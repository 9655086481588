import { useStaticQuery, graphql } from 'gatsby'
export const useEpisodes = () => {
  const { allPodcastEpisode } = useStaticQuery(
    graphql`
      query EpisodeQuery {
        allPodcastEpisode(
          sort: { fields: podcast___episodeNumber, order: ASC }
          filter: {
            podcast: {
              guid: { ne: "e626fd23-6583-46d3-9293-ad73c7f69e2b" }
              title: { ne: "Introducing: Spy Affair" }
            }
          }
        ) {
          nodes {
            podcast {
              pubDate
              guid
              title
              storyTitle
              content_encodedSnippet
              image
              customSnippet
              storySlug
              episodeNumber
              links {
                link
                service
              }
            }
          }
        }
      }
    `
  )
  return allPodcastEpisode.nodes
}
