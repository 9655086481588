import React from 'react'
import sectionStyles from '../styles/project-modules/about.module.less'

export default React.memo(function About(props) {
  return (
    <section className={sectionStyles.container}>
      <h3 className={sectionStyles.hed}>About this project</h3>
      <p className={sectionStyles.text}>
        Chronicle reporter Kevin Fagan spent nearly three years investigating
        the unsolved murders of gay men in 1974 and ’75 by a serial killer
        dubbed the Doodler by San Francisco police. The Doodler podcast,
        narrated by Fagan and produced in partnership with Ugly Duckling Films
        and Neon Hum Media, is available on your favorite podcast app.
      </p>
    </section>
  )
})
