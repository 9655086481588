import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import splashStyles from '../styles/project-modules/splash.module.less'
import SplashHeader from '../components/splashheader'
import Video from '../components/video'
import Subscribe from '../components/subscribe'
import Partners from '../components/partners'
import EpisodeList from '../components/episodeList'
import About from '../components/about'
// import placeholder from '../data/placeholder.png'

const IndexPage = ({ data, pageContext }) => {
  const {
    site: { siteMetadata },
    pagesSheetJson: thisPageDetails,
  } = data

  siteMetadata.PROJECT.TWITTER_TEXT = thisPageDetails.Social_Title
  siteMetadata.PAYWALL_SETTING = thisPageDetails.paywall

  return (
    <Layout
      meta={siteMetadata}
      thisPageDetails={thisPageDetails}
      pageContext={pageContext}
    >
      <main>
        <div className={splashStyles.bg}>
          <div className={splashStyles.container}>
            <header className={splashStyles.hed}>
              <SplashHeader
                title={
                  thisPageDetails.Title
                    ? thisPageDetails.Title
                    : siteMetadata.Title
                }
                deck={
                  thisPageDetails.Deck
                    ? thisPageDetails.Deck
                    : siteMetadata.Deck
                }
              />
            </header>
            <figure className={splashStyles.figure}>
              {/* <img
                src={placeholder}
                style={{ width: '100%', height: 'auto' }}
              /> */}
              <Video id={522028144} muted={true} className={'topper-video'} />
            </figure>
            <div className={splashStyles.textContainer}>
              <Subscribe />
            </div>
          </div>
        </div>

        <EpisodeList />
      </main>
      <About />

      <Partners />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        EMBEDDED
        MAIN_DOMAIN
        PAYWALL_SETTING
        PROJECT {
          AUTHORS {
            AUTHOR_NAME
            AUTHOR_PAGE
          }
          DATE
          DESCRIPTION
          HEARST_CATEGORY
          IMAGE
          ISO_MODDATE
          ISO_PUBDATE
          OPT_SLASH
          SLUG
          SOCIAL_TITLE
          SUBFOLDER
          TITLE
          TWITTER_TEXT
          ANALYTICS_CREDIT
          MARKET_KEY
        }
      }
    }
    pagesSheetJson(slug: { eq: $slug }) {
      slug
      Title
      Deck
      Social_ImageID
      SEO_Title
      SEO_Description
      Social_Title
      Social_ImageID
      Short_Title
      paywall
    }
  }
`
IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage
