import React from 'react'
import sectionStyles from '../styles/project-modules/subscribe.module.less'
import appleLogo from '../data/sfc/images/apple.svg'
import spotifyLogo from '../data/sfc/images/spotify.svg'
import stitcherLogo from '../data/sfc/images/stitcher.png'

function Subscribe({ className }) {
  return (
    <section className={`${sectionStyles.container} ${className}`}>
      <div className={sectionStyles.podcast}>
        <span className={sectionStyles.label}>Follow the podcast</span>
        <div className={sectionStyles.logos}>
          <a
            target="_blank"
            href="https://link.chtbl.com/thedoodler"
            rel="noreferrer"
          >
            <img
              className={sectionStyles.podcastLogo}
              src={appleLogo}
              alt="Listen on Apple podcasts"
            />
          </a>

          {/* <a
            rel="noreferrer"
            target="_blank"
            href="https://open.spotify.com/show/6pDJTEmNGmX8elAniLqMgG?si=dmvM1FUbRhaH-TqzxBwB-g"
          >
            <img
              className={sectionStyles.podcastLogo}
              src={spotifyLogo}
              alt="Listen on Spotify"
            />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href=" https://www.stitcher.com/show/the-doodler"
          >
            <img
              className="podcastLogo stitcherlogo"
              src={stitcherLogo}
              alt="Listen on Stitcher"
            />
          </a> */}
        </div>
      </div>
    </section>
  )
}

export default React.memo(Subscribe)
