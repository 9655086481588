import React from 'react'
import sectionStyles from '../styles/project-modules/splashheader.module.less'

export default React.memo(function SplashHeader({ title, deck }) {
  return (
    <div className={sectionStyles.container}>
      <div className={sectionStyles.text}>
        {/* <h2 className={sectionStyles.kicker}>Chronicle Podcast</h2> */}
        <h1 className={sectionStyles.title}>{title}</h1>
      </div>
      <p className={sectionStyles.dek}>{deck}</p>
    </div>
  )
})
