import React from 'react'
import { useEpisodes } from '../components/hooks/useEpisodes'
import sectionStyles from '../styles/project-modules/episodes.module.less'
import SafeLink from '../components/sfc/safelink'
import { capfirst, apdate } from 'journalize'
import WCMImage from '../components/sfc/wcmimage'

export default React.memo(function EpisodeList(props) {
  const episodes = useEpisodes()
  return (
    <section className={sectionStyles.container}>
      <h2 className={sectionStyles.title}>Episodes and stories</h2>
      <div className={sectionStyles.listContainer}>
        <ul className={sectionStyles.list}>
          {episodes.map((ep, idx) => (
            <li
              className={sectionStyles.episodeContainer}
              key={ep.podcast.guid}
            >
              <div className={sectionStyles.titleContainer}>
                <h3 className={sectionStyles.kicker}>
                  {idx === episodes.length - 1
                    ? 'Trailer'
                    : `Episode ${idx + 1}`}
                  <span className={sectionStyles.pubDate}>
                    {' '}
                    | {apdate(new Date(ep.podcast.pubDate))}
                  </span>
                </h3>
                <h4 className={sectionStyles.episodeTitle}>
                  {ep.podcast.title}
                </h4>
              </div>
              <div className={sectionStyles.links}>
                {ep.podcast.storyTitle && ep.podcast.storySlug && (
                  <div>
                    <span>Read ⇢</span>
                    <SafeLink
                      href={`/${ep.podcast.storySlug}`}
                      withinProject={true}
                    >
                      {ep.podcast.storyTitle}
                    </SafeLink>
                  </div>
                )}

                <div className={sectionStyles.subscribe}>
                  <span>Listen ⇢</span>
                  {ep.podcast.links.map((link) => (
                    <SafeLink
                      href={link.link}
                      withinProject={false}
                      withinSite={false}
                      key={link.service}
                    >
                      {capfirst(link.service)}
                    </SafeLink>
                  ))}
                </div>
              </div>
              {/* <div className={sectionStyles.description}> */}
              <WCMImage
                wcm={ep.podcast.image}
                alt="Episode promo image"
                className={sectionStyles.image}
                related={true}
              />

              <p>
                {ep.podcast.customSnippet
                  ? ep.podcast.customSnippet
                  : ep.podcast.content_encodedSnippet}
              </p>
              {/* </div> */}
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
})
